import './scss/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from "./Components/Header";
import Footer from "./Components/FooterLanding";
import {BrowserRouter} from "react-router-dom";


function App() {
  return (
      <BrowserRouter>
        <Header />
        <Footer />
      </BrowserRouter>
    );
}

export default App;
