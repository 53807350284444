import React from "react";


function VkLink() {
    return <a rel="noreferrer" href="https://vk.com/club220426176" target="_blank" className='underline text-white'>
        Группа Вконтакте
    </a>
}

export default VkLink;
