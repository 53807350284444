import React, {Component} from 'react';
import EmailLink from "./Links/EmailLink";
import VkLink from "./Links/VkLink";

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="pt-4 pb-1 footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-sm-5 col-lg-5 col-xs-12">
                                <h2><span className="mb-2 pb-1 pt-1 ps-3 shop_name">Рыболовный маркет Лещ</span></h2>
                                <p className="footer_desc text-white-50 mb-0">Открылся первый в Твери <span className="fishmarketlesch">Рыболовный Маркет "Лещ"</span>.</p>
                                <p className="footer_desc text-white-50 mb-1">Огромный ассортимент товаров для рыбалки любого вида. Низкие цены - высокое качество.</p>
                                <p className="footer_desc text-white-50 mt-1">Рыболовный Маркет "Лещ" в нем всегда всё есть</p>
                                {/*<p className="text-white-50 mt-1">Лови свою удачу на рыбалке вместе с Лещом!</p>*/}
                            </div>
                            <div className="col location">
                                <h2>Адреса</h2>
                                <a style={{display: "block", fontSize: "85%"}} rel="noreferrer" className='text-white underline' target='_blank' href='https://yandex.ru/maps/?text=Россия, Тверь, проспект Ленина 39'>Россия, Тверь, проспект Ленина 39</a>
                                <a style={{display: "block", fontSize: "85%"}} rel="noreferrer" className='text-white underline' target='_blank' href='https://yandex.ru/maps/?text=Россия, Тверь, проспект Победы 36/46'>Россия, Тверь, проспект Победы 36/46</a>
                                <h3 style={{marginTop: "12px"}}>Телефоны</h3>
                                <span style={{display: "inline-block", fontSize: "95%"}}><a href="tel:+79290977077" className="underline phone_number">+7 (929) 097-70-77</a><span style={{fontSize: "85%", display: "inline-block", marginLeft: "6px"}}>(Пр. Ленина)</span></span>
                                <span style={{display: "inline-block", fontSize: "95%"}}><a href="tel:+79038085236" className="underline phone_number">+7 (903) 808-52-36</a><span style={{fontSize: "85%", display: "inline-block", marginLeft: "6px"}}>(Пр. Победы)</span></span>
                                <h3 style={{marginTop: "12px"}}>Почта</h3>
                                <EmailLink />
                            </div>
                            <div className="col links">
                                <h2>Полезные ссылки</h2>
                                <ul className="p-0">
                                    <li>
                                        <VkLink />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="copyright">
                                <p><small className="text-white-50">© 2023. All Rights Reserved.</small></p>
                            </div>
                        </div>

                        <a href="https://metrika.yandex.ru/stat/?id=93161402&amp;from=informer"
                           target="_blank" rel="noreferrer">
                            <img src="https://metrika-informer.com/informer/93161402/3_1_333333FF_333333FF_1_pageviews"
                                 style={{width:'88px', height:'31px', border:'0'}} alt="Яндекс.Метрика"
                                 title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)" />
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
