import React from 'react';


const GalleryImgs = () => {

    let data = [
        {
            id: 1,
            imgSrc: "/gallery/33.jpeg"
        },
        {
            id: 2,
            imgSrc: "/gallery/10.jpeg"
        },
        {
            id: 3,
            imgSrc: "/gallery/53.jpeg"
        },
        {
            id: 4,
            imgSrc: "/gallery/5.jpeg"
        },
        {
            id: 5,
            imgSrc: "/gallery/6.jpeg"
        },
        {
            id: 6,
            imgSrc: "/gallery/7.jpeg"
        },
        {
            id: 7,
            imgSrc: "/gallery/21.jpeg"
        },
        {
            id: 8,
            imgSrc: "/gallery/26.jpeg"
        },
        {
            id: 9,
            imgSrc: "/gallery/47.jpeg"
        },
        {
            id: 10,
            imgSrc: "/gallery/36.jpeg"
        },
        {
            id: 11,
            imgSrc: "/gallery/52.jpeg"
        },
        {
            id: 12,
            imgSrc: "/gallery/49.jpeg"
        },
        {
            id: 13,
            imgSrc: "/gallery/37.jpeg"
        },
        {
            id: 14,
            imgSrc: "/gallery/54.jpeg"
        },
        {
            id: 15,
            imgSrc: "/gallery/44.jpeg"
        },
        {
            id: 16,
            imgSrc: "/gallery/45.jpeg"
        },
        {
            id: 17,
            imgSrc: "/gallery/51.jpeg"
        },
        {
            id: 18,
            imgSrc: "/gallery/19.jpeg"
        },
        {
            id: 19,
            imgSrc: "/gallery/34.jpeg"
        },
        {
            id: 20,
            imgSrc: "/gallery/31.jpeg"
        },
        {
            id: 21,
            imgSrc: "/gallery/23.jpeg"
        },
        {
            id: 22,
            imgSrc: "/gallery/41.jpeg"
        },
        {
            id: 23,
            imgSrc: "/gallery/22.jpeg"
        },
        {
            id: 24,
            imgSrc: "/gallery/39.jpeg"
        },
        {
            id: 25,
            imgSrc: "/gallery/38.jpeg"
        },
        {
            id: 26,
            imgSrc: "/gallery/16.jpeg"
        },
        {
            id: 27,
            imgSrc: "/gallery/46.jpeg"
        },
        {
            id: 28,
            imgSrc: "/gallery/8.jpeg"
        },
        {
            id: 29,
            imgSrc: "/gallery/40.jpeg"
        },
        {
            id: 30,
            imgSrc: "/gallery/20.jpeg"
        },
        {
            id: 31,
            imgSrc: "/gallery/27.jpeg"
        },
        {
            id: 32,
            imgSrc: "/gallery/48.jpeg"
        },
        {
            id: 33,
            imgSrc: "/gallery/4.jpeg"
        },
        {
            id: 34,
            imgSrc: "/gallery/17.jpeg"
        },
        {
            id: 35,
            imgSrc: "/gallery/56.jpeg"
        },
        {
            id: 36,
            imgSrc: "/gallery/12.jpeg"
        },
        {
            id: 37,
            imgSrc: "/gallery/15.jpeg"
        },
        {
            id: 38,
            imgSrc: "/gallery/42.jpeg"
        },
        {
            id: 39,
            imgSrc: "/gallery/9.jpeg"
        },
        {
            id: 40,
            imgSrc: "/gallery/14.jpeg"
        },
        {
            id: 41,
            imgSrc: "/gallery/50.jpeg"
        },
        {
            id: 42,
            imgSrc: "/gallery/13.jpeg"
        },
        {
            id: 43,
            imgSrc: "/gallery/2.jpeg"
        },
        {
            id: 44,
            imgSrc: "/gallery/29.jpeg"
        },
        {
            id: 45,
            imgSrc: "/gallery/18.jpeg"
        },
        {
            id: 46,
            imgSrc: "/gallery/30.jpeg"
        },
        {
            id: 47,
            imgSrc: "/gallery/24.jpeg"
        },
        {
            id: 48,
            imgSrc: "/gallery/35.jpeg"
        },
        {
            id: 49,
            imgSrc: "/gallery/57.jpeg"
        },
        {
            id: 50,
            imgSrc: "/gallery/1.jpeg"
        },
        {
            id: 51,
            imgSrc: "/gallery/28.jpeg"
        },
        {
            id: 52,
            imgSrc: "/gallery/3.jpeg"
        },
        {
            id: 53,
            imgSrc: "/gallery/55.jpeg"
        },
        {
            id: 54,
            imgSrc: "/gallery/25.jpeg"
        },
        {
            id: 55,
            imgSrc: "/gallery/43.jpeg"
        },
    ]

    return (
        <div style={{display: "flex", justifyContent: 'center'}}>
            <div className="gallery">
                {data.map((item, index) => {
                    return (
                        <div className="pics" key={index}>
                            <img src={item.imgSrc} alt={item.id} style={{width: "100%"}} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GalleryImgs;
