import React, {Component} from 'react';
import GalleryImgs from "../Components/GalleryImgs";

class Gallery extends Component {
    render() {
        return (
            <GalleryImgs />
        )
    }
}

export default Gallery;