import React, {Component} from 'react';
import Carousel from "react-bootstrap/Carousel";
import slider_1 from "../Assets/orig.png";
import slider_2 from "../Assets/snasti_dlja_lovliokunja_na-spinning-356x189.jpg";
import slider_3 from "../Assets/udochka-rybalka-snasti-leska-poplavki-stol-boke.jpg";
import slider_4 from "../Assets/6116283639.jpg";

class MainCarousel extends Component {
    render() {
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider_1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>Рыболовный Маркет Лещ</h3>
                        <p>Мы открылись, приглашаем всех!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider_2}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h3>Рыболовный маркет Твери</h3>
                        <p>Огромный выбор ассортимента на любой вкус</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider_3}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Надёжность и удобство</h3>
                        <p>
                            Наше снаряжение точно не будет "чёрным пятном" на рыбалке
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider_4}
                        alt="Fourth slide"
                    />

                    <Carousel.Caption>
                        <h3>Дружелюбность</h3>
                        <p>
                            Мы всегда будем рады помочь вам с выбором и порекомендуем лучшее оснащение
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        );
    }
}

export default MainCarousel;