import React, {Component} from 'react';

class Contacts extends Component {
    render() {
        return (
            <div className="row">
                <iframe
                    className="col map"
                    title="Карта с адресом маркета в Твери"
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae50aaf59b3e0a09e7b9becb7875e9833467f4f0960b986f048af38a9bf948913&amp;source=constructor"
                    width="500px" height="420px"/>
                <div id="contact" className="col">
                    <div className="splspan">
                        <span className="text-line"/><h2 className="arttitle">
                        Контакты
                    </h2><span className="text-line"/>
                    </div>
                    <ul className="contact_els">
                        <li className="contactli" style={{color: "rgba(15,15,15,0.8)"}}>Тверь</li>
                        <li><a rel="noreferrer" className='underlineblack contactli'
                               style={{fontWeight: "400", color: "rgba(15,15,15,0.8)"}} target='_blank'
                               href='https://yandex.ru/maps/-/CDfsu2kY'>Проспект Ленина 39</a></li>
                        <li><p className="phone_number">
                            <a href="tel:+79290977077" style={{color: "black", fontWeight: "400"}}
                               className="underlineblack">+7 (929) 097-70-77</a>
                        </p></li>
                        <li><a rel="noreferrer" className='underlineblack contactli'
                               style={{fontWeight: "400", color: "rgba(15,15,15,0.8)"}} target='_blank'
                               href='https://yandex.ru/maps/-/CDfszEKF'>Проспект Победы 36/46</a></li>
                        <li><p className="phone_number">
                            <a href="tel:+79038085236" style={{color: "black", fontWeight: "400"}}
                               className="underlineblack">+7 (903) 808-52-36</a>
                        </p></li>
                    </ul>
                    <hr/>
                    <h3 className="timework">Часы работы:</h3>
                    <h5 id="timeworkcur">ОТКРЫТО:</h5>
                    <h5 id="timeworkcur">9:00–19:00</h5>
                    <h5 id="timeworkcur">БЕЗ ВЫХОДНЫХ</h5>
                    <hr/>
                </div>
            </div>
        );
    }
}

export default Contacts;