import React, {Component} from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import logo from '../Assets/favicon.ico'
import {Route, Routes} from "react-router";
import Home from "../Pages/Home";
import Contacts from "../Pages/Contacts";
import Gallery from "../Pages/Gallery";


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: {},
        }
    }

    componentDidMount() {
        // axios.get("https://api.lesch-tver.ru/api/apivars/")
        //     .then((response) => {
        //             this.setState({
        //                 config: response.data[0],
        //             })
        //         }
        //     )
    }

    render() {
        return (
            <>
                <Navbar className='mb-auto' id="header" collapseOnSelect expand='md' variant='dark'>
                    <Container>
                        <Navbar.Brand href="/" className="brandnamelogo">
                            <span className="headerleschname">Рыболовный</span>
                            <img src={logo}
                                 height='60'
                                 width='60'
                                 className='d-inline-block align-top'
                                 alt='Логотип Леща'
                            />
                            <span className="headerleschname">Маркет</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='me-auto'>
                                <Nav.Link rel="canonical" className="header_link" href='/'>Главная</Nav.Link>
                                <Nav.Link rel="canonical" className="header_link" href='/contacts'>Контакты</Nav.Link>
                                <Nav.Link rel="canonical" className="header_link" href='/gallery'>Галерея</Nav.Link>
                                {/*<Nav.Link className="header_link" target="_blank" href='https://kaida-fish.ru/catalog/'>Каталог</Nav.Link>*/}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/contacts' element={<Contacts/>}/>
                    <Route path='/gallery' element={<Gallery/>}/>
                </Routes>
            </>
        );
    }
}

export default Header
